import React, { useCallback, useRef, useState } from "react";
import { Box, Button, FormControl, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import DatePicker from "react-datepicker";
import Icon from "@mdi/react";
import { mdiTrayArrowDown } from "@mdi/js";
import "react-datepicker/dist/react-datepicker.css";
import { exportFile } from "src/utils/export-file";
import { ApiConfig } from "src/api/config";
import { API_ROUTES } from "src/constants/api-routes";
import dateHelper from "src/utils/date-helper";
import { FilterDropDown } from "src/components/app/FilterDropdown/FilterDropDown";
import HorizontalLoading from "src/components/app/HorizontalLoading";
import { ControlledSelectBox } from "src/components/app/SelectBox";
import { useAsync } from "src/hooks/useAsync";
import { IRiderListItem, RiderSorts } from "src/api/types/riders";
import { panelRidersService } from "src/api/services/riders";
import { useAbortController } from "src/hooks/useAbortController";
import { unique } from "src/utils/iterate";
import { useForm } from "react-hook-form";
import { sanitizeRiderName } from "src/utils/string";
import { debounce } from "lodash";
import { panelZonesService } from "src/api/services/zones";
import {
  DEFAULT_COUNT,
  DEFAULT_OFFSET,
} from "src/constants/api-default-values";
import { ZoneSorts } from "src/api/types/zones";
import { IDropDownItem } from "src/types/dropdownitem";
import { panelGroupsService } from "src/api/services/groups";

const RiderOrdersReport: React.FC = () => {
  const { t } = useTranslation();
  const { getAbortSignal } = useAbortController();
  const { control, getValues } = useForm();
  useTitle(t("pageTitles.riderOrdersReport"));
  const [formData, setFormData] = useState({
    fromDate: "",
    toDate: "",
  });
  const exportButton = useRef<any>();
  const ridersOffset = useRef<number>(0);
  const selectedZones = useRef<string[]>([]);
  const selectedGroups = useRef<string[]>([]);

  const fetchRidersAsync = useAsync<Partial<IRiderListItem>[], any>(
    (options) => {
      return new Promise(async (resolve, reject) => {
        let {
          offset = ridersOffset.current,
          riderId = "",
          keyword = undefined,
        } = options || {};
        try {
          const result = await panelRidersService.getAll(
            offset,
            50,
            RiderSorts.ByNameAsc,
            getAbortSignal("fetchRiders").signal,
            keyword,
            riderId
          );
          if (!keyword) ridersOffset.current += 50;
          resolve(
            unique([
              {
                id: undefined,
                firstName: "All",
                lastName: "",
              },
              ...(fetchRidersAsync?.data || []),
              ...result.data.data.items,
            ])
          );
        } catch (error) {
          console.log(error);
        } finally {
          resolve([]);
        }
      });
    },
    { immediate: true }
  );

  // const { data: zones } = useAsync<IDropDownItem[], any>(
  //   () => {
  //     return new Promise(async (resolve, reject) => {
  //       try {
  //         const res = await panelZonesService.getAll(
  //           DEFAULT_OFFSET,
  //           DEFAULT_COUNT,
  //           ZoneSorts.ByNameEnAsc,
  //           getAbortSignal("fetchZones").signal
  //         );
  //         const zonesListMap = res.data.data.items.map((item) =>
  //           generateListMap(item)
  //         );
  //         resolve(zonesListMap);
  //       } catch (error) {
  //         console.log(error);
  //       } finally {
  //         resolve([]);
  //       }
  //     });
  //   },
  //   { immediate: true }
  // );

  // const { data: groups } = useAsync<IDropDownItem[], any>(
  //   () => {
  //     return new Promise(async (resolve, reject) => {
  //       try {
  //         const res = await panelGroupsService.getAll(
  //           DEFAULT_OFFSET,
  //           DEFAULT_COUNT,
  //           getAbortSignal("fetchGroups").signal
  //         );
  //         const groupsListMap = res.data.data.items.map((item) =>
  //           generateListMap(item)
  //         );
  //         resolve(groupsListMap);
  //       } catch (error) {
  //         console.log(error);
  //       } finally {
  //         resolve([]);
  //       }
  //     });
  //   },
  //   { immediate: true }
  // );

  // const generateListMap = (item: { id: string; nameEn: string }) => {
  //   return {
  //     id: item.id,
  //     text: item.nameEn,
  //     searchableFields: {
  //       nameEn: item.nameEn.trim(),
  //     },
  //   };
  // };

  const exportCSV = () => {
    const { toDate, fromDate } = formData;
    exportFile(
      ApiConfig.baseURL + API_ROUTES.ORDERS.EXPORT_RIDER_ORDERS_REPORT,
      {
        From: fromDate,
        To: toDate,
        ...(getValues("riderIds") ? { RiderId: getValues("riderIds") } : {}),
        // ZoneId: selectedZones.current,
        // GroupId: selectedGroups.current,
      },
      exportButton,
      `RiderOrdersReport-${toDate}|${fromDate}`
    );
  };

  const debouncedFetchRiders = useCallback(
    debounce((keyword) => {
      if (!!keyword && !fetchRidersAsync.isLoading) {
        fetchRidersAsync.run({ keyword, offset: 0 });
      }
    }, 500),
    [fetchRidersAsync]
  );

  const debouncedScroll = useCallback(
    debounce(() => {
      fetchRidersAsync.run({});
    }, 500),
    [fetchRidersAsync]
  );

  // const onGroupDropDownChanged = (data: string[]) => {
  //   selectedGroups.current = data;
  // };
  // const onZonesDropDownChanged = (data: string[]) => {
  //   selectedZones.current = data;
  // };

  return (
    <Box>
      <Box alignItems="center">
        <Text as="h3" fontSize="3xl">
          {t("titles.riderOrdersReport")}
        </Text>
      </Box>
      <Box className="flex_filters" mb="4" mt="4">
        <Box className="filters_container">
          <Box className="reactDatePicker_container">
            <label>From</label>
            <DatePicker
              dateFormat="MM/dd/yyyy"
              className="reactDatePicker"
              placeholderText="MM/dd/yyyy"
              {...(formData.fromDate
                ? {
                    selected: new Date(formData.fromDate),
                  }
                : {})}
              onChange={(date: Date) => {
                let updatedFromDate = dateHelper.formatDate(
                  date.toString(),
                  "YYYY-MM-DD HH:mm"
                );
                setFormData({
                  ...formData,
                  fromDate: updatedFromDate,
                });
              }}
            />
          </Box>
          <Box className="reactDatePicker_container">
            <label>To</label>
            <DatePicker
              dateFormat="MM/dd/yyyy"
              placeholderText="MM/dd/yyyy"
              className="reactDatePicker"
              {...(formData.toDate
                ? {
                    selected: new Date(formData.toDate),
                  }
                : {})}
              onChange={(date: Date) => {
                let updatedFromDate = dateHelper.formatDate(
                  date.toString(),
                  "YYYY-MM-DD HH:mm"
                );
                setFormData({
                  ...formData,
                  toDate: updatedFromDate.toString(),
                });
              }}
            />
          </Box>
          <FormControl>
            {fetchRidersAsync.isLoading && <HorizontalLoading />}
            <label>Rider</label>
            <ControlledSelectBox
              control={control}
              name={"riderIds"}
              placeholder={"Select a Rider"}
              options={fetchRidersAsync.data?.map((rider) => ({
                value: rider.id,
                label: sanitizeRiderName(rider),
              }))}
              styles={{
                input: (base) => ({
                  ...base,
                  minWidth: 178,
                  marginTop: 0,
                }),
                container: (base) => ({
                  ...base,
                  marginTop: 0,
                  maxWidth: 178,
                }),
              }}
              isLoading={fetchRidersAsync.isLoading}
              onMenuScrollToBottom={() => debouncedScroll()}
              onInputChange={(keyword) => debouncedFetchRiders(keyword)}
            />
          </FormControl>
          {/* <FilterDropDown
            filterBoxTitle="Zone(s)"
            defaultSelectedValues={[]}
            data={zones || []}
            onChange={onZonesDropDownChanged}
          />
          <FilterDropDown
            filterBoxTitle="Group(s)"
            defaultSelectedValues={[]}
            data={groups || []}
            onChange={onGroupDropDownChanged}
          /> */}
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            bg="#F39905"
            color="#333"
            ml="2"
            pr="20px"
            pl="20px"
            ref={exportButton}
            disabled={!formData.toDate || !formData.fromDate}
            onClick={() => exportCSV()}
          >
            <Icon path={mdiTrayArrowDown} size="25px" color="#333" />
            &nbsp;Export
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default RiderOrdersReport;
