import React, { useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Icon from "@mdi/react";
import { mdiEye, mdiPencil, mdiPlus, mdiTable } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import HorizontalLoading from "src/components/app/HorizontalLoading";
import { Link } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { Pagination } from "src/components/app/Pagination";
import { useAsync } from "../../../hooks/useAsync";
import { panelTargetGroupsService } from "../../../api/services/target-groups";
import { useAbortController } from "../../../hooks/useAbortController";
import dateHelper from "../../../utils/date-helper";
import { IPaginatedResult } from "../../../api/types/axios-response";
import { ITargetGroupListItem } from "../../../api/types/target-groups";
import { IGetAllType } from "../../../api/types/dashboard";
import useQueryParam from "../../../hooks/useSearchQuery";
import { modalActions } from "../../../global-context/modals";
import { MODAL_TYPES } from "../../../types/modals";

const TargetGroups: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t("pageTitles.groups"));
  const { getAbortSignal } = useAbortController();

  const { queryParams, updateQueryParams, initialized } = useQueryParam({
    initialFields: {
      count: 10,
      offset: 0,
    },
  });

  const fetchTargetGroups = useAsync<
    IPaginatedResult<ITargetGroupListItem>,
    IGetAllType
  >((payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await panelTargetGroupsService.getAll(
          {
            ...queryParams,
            ...payload,
          },
          getAbortSignal("fetchTargetGroups").signal
        );
        resolve(response.data.data);
      } catch (e) {
        return reject(e);
      }
    });
  });

  function onPaginate(offset: number, size: number) {
    updateQueryParams({ offset, size });
    fetchTargetGroups.run({ offset, count: size });
  }

  useEffect(() => {
    if (initialized) fetchTargetGroups.run({});
  }, [initialized]);

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Text as="h3" fontSize="3xl">
          {t("titles.targetGroups")}
        </Text>
        <IconButton
          aria-label="New Target Group"
          as={Link}
          to={ROUTE_CONSTANTS.DASHBOARD.TARGET_GROUPS.CREATE.ABSOLUTE}
          ml="4"
          icon={<Icon path={mdiPlus} size="28px" />}
          colorScheme="primary"
          variant="outline"
          size="sm"
        />
      </Box>
      <Box height="5px" mb="1">
        {fetchTargetGroups.isLoading && <HorizontalLoading />}
      </Box>
      <Box className="table_container">
        <Table
          variant="simple"
          colorScheme="blackAlpha"
          size="md"
          boxShadow="md"
          backgroundColor="white"
          borderRadius="md"
        >
          <Thead>
            <Tr>
              <Th>{"ID"}</Th>
              <Th>{"Target Group"}</Th>
              <Th>{"Daily Target"}</Th>
              <Th>{"Created At"}</Th>
              <Th>{"Numbers Of Riders"}</Th>
              <Th>{"Action"}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {fetchTargetGroups.data?.items?.map((targetGroupItem, index) => (
              <Tr key={index}>
                <Td>{targetGroupItem.id}</Td>
                <Td>{targetGroupItem.name}</Td>
                <Td>{targetGroupItem.dailyTarget}</Td>
                <Td>{dateHelper.formatDate(targetGroupItem.createdAt)}</Td>
                <Td>{targetGroupItem.riderCount}</Td>
                <Td>
                  <IconButton
                    aria-label="Test"
                    icon={<Icon path={mdiEye} size="24px" />}
                    color="gray.100"
                    bg="blue.300"
                    as={Link}
                    to={
                      ROUTE_CONSTANTS.DASHBOARD.TARGET_GROUPS.DETAILS.BY_DATA(
                        targetGroupItem.id
                      ).ABSOLUTE
                    }
                    ml={"2"}
                  />
                  <IconButton
                    aria-label="Test"
                    icon={<Icon path={mdiTable} size="24px" />}
                    color="gray.100"
                    bg="blue.300"
                    disabled={!targetGroupItem.riderCount}
                    as={Button}
                    onClick={() =>
                      modalActions.addModal(MODAL_TYPES.TARGET_GROUP_RIDERS, {
                        targetGroupId: targetGroupItem.id,
                        targetGroupName: targetGroupItem.name,
                      })
                    }
                    ml={"2"}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      {!initialized && <HorizontalLoading />}
      {initialized && (
        <Pagination
          entityTitle="Group"
          pageTotalCount={fetchTargetGroups.data?.totalCount || 0}
          perPageItems={parseInt(queryParams.size || 10)}
          passPaginateValues={onPaginate}
        />
      )}
    </Box>
  );
};
export default TargetGroups;
