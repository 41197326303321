import { IThirdPartyCreateUpdateFormData, IThirdPartyItem } from "src/types/third-party";
import { AxiosInstance } from "..";
import { API_ROUTES } from "src/constants/api-routes";
import { IThirdPartyListPayload } from "../types/third-party";
import { IAxiosResponse } from "../types/axios-response";

export const panelThirdPartiesService = {
    getInfo(id: string, abortSignal: AbortSignal) {
        return AxiosInstance.get<IAxiosResponse<IThirdPartyItem>>(`${API_ROUTES.THIRD_PARTIES.GET_INFO(id)}`, { signal: abortSignal });
    },
    create(data: IThirdPartyCreateUpdateFormData, abortSignal: AbortSignal) {
        return AxiosInstance.post<IAxiosResponse<any>>(`${API_ROUTES.THIRD_PARTIES.CREATE}`, data, { signal: abortSignal });
    },
    update(id:string, data: IThirdPartyCreateUpdateFormData, abortSignal: AbortSignal) {
        return AxiosInstance.put<IAxiosResponse<any>>(`${API_ROUTES.THIRD_PARTIES.UPDATE(id)}`, data, { signal: abortSignal });
    },
    getAll(data: {offset: number, count: number, searchQuery: string}, abortSignal: AbortSignal) {
        let { offset, count, searchQuery } = data;
        let keyword = searchQuery ? `&keyword=${searchQuery}` : "";
        return AxiosInstance.get<IAxiosResponse<IThirdPartyListPayload>>(`${API_ROUTES.THIRD_PARTIES.GET_ALL}?count=${count}&offset=${offset}${keyword}`, { signal: abortSignal })
    },
    toEnableItem(id: string, abortSignal: AbortSignal) {
        return AxiosInstance.put<IAxiosResponse<{}>>(`${API_ROUTES.THIRD_PARTIES.ENABLE_ITEM(id)}`, {signal: abortSignal});
    },
    toDisableItem(id: string, abortSignal: AbortSignal) {
        return AxiosInstance.put<IAxiosResponse<{}>>(`${API_ROUTES.THIRD_PARTIES.DISABLE_ITEM(id)}`, {signal: abortSignal});
    },
    generateAPIKey(id: string, abortSignal: AbortSignal) {
        return AxiosInstance.put<IAxiosResponse<string>>(`${API_ROUTES.THIRD_PARTIES.GENERATE_API_KEY(id)}`, {signal: abortSignal});
    }
}