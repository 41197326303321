import { API_ROUTES } from "src/constants/api-routes";
import {AxiosInstance} from "../index";
import { IAxiosResponse } from "../types/axios-response";
import { INotificationsListPayload } from "../types/notifications";

export const panelNotificationService = {
    getNotificationCount(abortSignal:AbortSignal){
        return AxiosInstance.get<IAxiosResponse<number>>(API_ROUTES.NOTIFICATION.GET_NOTIFICATION_COUNT,{signal:abortSignal});
    },
    getNotifications(offset:number, count:number, abortSignal:AbortSignal){
        return AxiosInstance.get<IAxiosResponse<INotificationsListPayload>>(`${API_ROUTES.NOTIFICATION.GET_NOTIFICATIONS}?offset=${offset}&count=${count}`,{signal:abortSignal});
    },
    readNotification(data:{date:string}){
        return AxiosInstance.post(API_ROUTES.NOTIFICATION.READ_NOTIFICATIONS,data);
    }
}