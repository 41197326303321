import React, { useCallback, useState } from "react";
import { modalActions, useModals } from "src/global-context/modals";
import { useNavigate } from "react-router-dom";
import { ModalsList } from "./modalsList";
import { ModalProps } from "../../../types/modals";

const ModalCreator = () => {
  const navigate = useNavigate();
  const modals = useModals((s) => s.modals);
  const [hardwareBackHistory, setHardwareBackHistory] = useState(true);

  const closeEvent = useCallback(() => {
    modalActions.removeLastModal();
  }, []);

  const close = (clearModals?: boolean) => {
    setHardwareBackHistory(false);
    if (clearModals) {
      modalActions.clearModals();
    } else {
      navigate(-1);
      modalActions.removeLastModal();
    }
    setTimeout(() => {
      setHardwareBackHistory(true);
    }, 0);
  };

  // useEffect(() => {
  //     if (modals.length && hardwareBackHistory) {
  //         window.addEventListener("popstate", closeEvent);
  //     }
  //     return () => window.removeEventListener("popstate", closeEvent);
  // }, [modals, hardwareBackHistory, closeEvent]);

  return (
    <>
      {modals.map((modal: any, index: any) => {
        //@ts-ignore
        const ModalComponent = ModalsList[modal.type] as React.FC<ModalProps>;
        return (
          <>
            <ModalComponent data={modal.data} closeModal={close} />
          </>
        );
      })}
    </>
  );
};
export default ModalCreator;
