import { IROleData, USER_ROLE } from "src/types/user-management";

export const roleData: IROleData[] = [
    {
        tabIndex: 0,
        role: USER_ROLE.Admin,
        title: "Admin"
    },
    {
        tabIndex: 1,
        role: USER_ROLE.GroupAgent,
        title: "Group Manager"
    },
    {
        tabIndex: 2,
        role: USER_ROLE.ZoneAgent,
        title: "Zone Agent"
    },
]