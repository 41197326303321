import { FormControl, Switch } from "@chakra-ui/react";
import React, { useState } from "react";
import { IThirdPartyItem } from "src/types/third-party";
import ChangeStateConfirmation from "../ChangeStatusConfirmation";

interface IThirdPartyStateSwitchProps {
    item: IThirdPartyItem;
    openSwitchConfirmationModal: boolean
    setOpenSwitchConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ThirdPartyStateSwitch:React.FC<IThirdPartyStateSwitchProps> = ({ item, openSwitchConfirmationModal, setOpenSwitchConfirmationModal }) => {
    const [isChecked, setIsChecked] = useState<boolean>(item.active);

    const handleChangeState = (newState: boolean) => {
        console.log("====>", newState);
        setIsChecked(newState);
    }
    
    return <>
        <FormControl>
            <Switch colorScheme={isChecked ? "yellow" : "gray"} onChange={() => setOpenSwitchConfirmationModal(true)} isChecked={isChecked} />
        </FormControl>
        {
            openSwitchConfirmationModal &&
            <ChangeStateConfirmation
                item={{id: item.id, name: item.companyNameEn, isActive: isChecked}}
                openSwitchConfirmationModal={openSwitchConfirmationModal}
                setOpenSwitchConfirmationModal={setOpenSwitchConfirmationModal}
                handleChangeState={handleChangeState}/>
        }
    </>
};

export default ThirdPartyStateSwitch;