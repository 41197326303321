import { IconButton, Menu, MenuButton, MenuItem, MenuList, Td, Tr } from "@chakra-ui/react";
import { mdiDotsVertical, mdiKeyVariant, mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { panelThirdPartiesService } from "src/api/services/third-party";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { IThirdPartyItem } from "src/types/third-party";
import dateHelper from "src/utils/date-helper";
import APIKeyConfirmation from "../APIKeyConfirmation";
import ThirdPartyStateSwitch from "../ThirdPartyStateSwitch";

interface IThirdPartyRowProps {
    item: IThirdPartyItem;
}

const ThirdPartyRow: React.FC<IThirdPartyRowProps> = ({ item }) => {
    const navigate = useNavigate();
    const [openSwitchConfirmationModal, setOpenSwitchConfirmationModal] = useState<boolean>(false);
    const [openAPIKeyConfirmationModal, setOpenAPIKeyConfirmationModal] = useState<boolean>(false);
    const [thirdPartyInfo, setThirdPartyInfo] = useState<IThirdPartyItem>(item);
    const [abortController] = useState<AbortController>(new AbortController());

    const getThirdPartyInfo = async () => {
        try {
            const result = await panelThirdPartiesService.getInfo(thirdPartyInfo.id, abortController.signal);
            setThirdPartyInfo(result.data.data);
        } catch(error) {
            console.log(error);
        }
    }

    useEffect(() => {
        return () => {
            abortController.abort();
        }
    }, []);

    return <>
        <Tr>
            <Td>{thirdPartyInfo.companyNameEn}</Td>
            <Td>{thirdPartyInfo.domain || "---"}</Td>
            <Td>{thirdPartyInfo.contactNo || "---"}</Td>
            <Td>{dateHelper.formatDate(thirdPartyInfo.registerDate)}</Td>
            <Td>{<ThirdPartyStateSwitch
                item={item}
                openSwitchConfirmationModal={openSwitchConfirmationModal}
                setOpenSwitchConfirmationModal={setOpenSwitchConfirmationModal} />
            }</Td>
            <Td>{dateHelper.formatDate(thirdPartyInfo.registeredApiKeyDate)}</Td>
            <Td>
                <Menu>
                    <MenuButton
                        as={IconButton}
                        aria-label='Options'
                        icon={<Icon path={mdiDotsVertical} size="25px" />}
                        variant="ghost"
                    />
                    <MenuList>
                        <MenuItem onClick={() => navigate(ROUTE_CONSTANTS.DASHBOARD.THIRD_PARTIES.UPDATE.BY_DATA(thirdPartyInfo.id).ABSOLUTE)}
                            icon={<Icon path={mdiPencil} size="25px" />}>Edit</MenuItem>
                        <MenuItem onClick={() => setOpenAPIKeyConfirmationModal(true)}
                            icon={<Icon path={mdiKeyVariant} size="25px" />}>Generate API key</MenuItem>
                    </MenuList>
                </Menu>
            </Td>
        </Tr>
        {
            openAPIKeyConfirmationModal &&
                <APIKeyConfirmation
                    item={{id: thirdPartyInfo.id, name: thirdPartyInfo.companyNameEn}}
                    openAPIKeyConfirmationModal={openAPIKeyConfirmationModal}
                    setOpenAPIKeyConfirmationModal={setOpenAPIKeyConfirmationModal}
                    handleGenerateNewKey={getThirdPartyInfo}/>
        }
    </>;
}

export default ThirdPartyRow;