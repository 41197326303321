import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import urlHelper from "src/utils/url-helper";
import storeHelper from "src/utils/store-helper";

interface IUseSearch {
  isStoreOnLocalStorage: boolean;
  entity: string;
  fields: Record<string, any>;
}

export const useSearch = ({
  isStoreOnLocalStorage,
  entity,
  fields,
}: IUseSearch) => {
  const lsEntityObject = storeHelper.getFromLS(entity);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState<Record<string, any>>(
    setSearchQueryFromWindow()
  );
  function setSearchQueryFromWindow() {
    let obj = {} as Record<string, string>;
    for (const key in fields) {
      if (Object.prototype.hasOwnProperty.call(fields, key)) {
        if (isStoreOnLocalStorage) {
          obj[key] =
            lsEntityObject && lsEntityObject.hasOwnProperty(key)
              ? lsEntityObject[key]
              : fields[key];
        } else {
          obj[key] = searchParams.get(key) || fields[key];
        }
      }
    }

    return obj;
  }
  const setSearchQueryFromValue = () => {
    let obj = {} as Record<string, string>;
    for (const key in searchQuery) {
      if (Object.prototype.hasOwnProperty.call(searchQuery, key)) {
        obj[key] = isStoreOnLocalStorage
          ? lsEntityObject && lsEntityObject.hasOwnProperty(key)
            ? lsEntityObject[key]
            : fields[key]
          : searchQuery[key];
      }
    }

    Object.entries(obj).forEach(([key, val]) => {
      const isNullish = obj[key] === undefined || obj[key] === "";
      if (isNullish) delete obj[key];
    });

    return obj;
  };

  useEffect(() => {
    !isStoreOnLocalStorage &&
      setSearchParams({
        ...urlHelper.generateQueryString(),
        ...setSearchQueryFromValue(),
      });
    if (isStoreOnLocalStorage) {
      storeHelper.setToLS(entity, { ...setSearchQueryFromValue() });
    }
  }, [searchQuery]);

  const updateSearchParameters = (searchParamsObj: {}) => {
    if (isStoreOnLocalStorage) {
      storeHelper.setToLS(entity, { ...lsEntityObject, ...searchParamsObj });
    }

    setSearchQuery((prevQuery) => ({ ...prevQuery, ...searchParamsObj }));
  };

  const updateSearchParametersArray = (searchParamsObj : Record<string, string[]>) => {
    const newQuery = searchQuery;
    Object.entries(searchParamsObj).forEach(([key,val]) => {
      newQuery[key] = val
      searchParams.set(key , val[0])
      val.slice(1).forEach((arrayObj) => {
        searchParams.append(key , arrayObj)
      })
    });
    // setSearchQuery(newQuery);
    // setSearchParams(setSearchQueryFromValue());
  }

  const removeSearchParameters = (name: string) => {
    const newQuery = searchQuery;
    delete newQuery[name];
    searchParams.delete(name);
    setSearchQuery(newQuery);
    setSearchParams({
      ...setSearchQueryFromValue(),
    });
  };

  console.log(searchQuery);

  return {
    searchQuery,
    setSearchQuery,
    updateSearchParameters,
    searchParams,
    removeSearchParameters,
    updateSearchParametersArray
  };
};
