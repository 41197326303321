import { Center, Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ordersService } from "src/api/services/orders";
import { IOngoingOrders } from "src/api/types/orders";
import { NotFoundResult } from "src/components/app/NotFoundResult";
import { DEFAULT_COUNT, DEFAULT_OFFSET } from "src/constants/api-default-values";
import { useOrderDataDispatch, useOrderDataState } from "../../OrdersDataContext";
import { OrdersDataStoreActionKind } from "../../OrdersDataStore/OrdersDataStore-Actions";
import AcceptedRiders from "../AcceptedRiders";
import EligibleRiders from "../EligibleRiders";
import OngoingOrderItem from "../OngoingOrderItem";
import dateHelper from "src/utils/date-helper";
import SPSpinner from "src/components/app/SPSpinner";
import "./styles.css";

interface IOngoingOrderListProps {
    allowToCallAPI: boolean;
    tabIsActive: boolean;
}

const OngoingOrderList: React.FC<IOngoingOrderListProps> = ({ allowToCallAPI, tabIsActive }) => {
    const { t } = useTranslation();
    const dispatch = useOrderDataDispatch();
    const { orders } = useOrderDataState();

    const [getAbortController] = useState<AbortController>(new AbortController());
    const [showNoResultFound, setShowNoResultFound] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true); // we set loading true on initial page for seeing loading state (better UX)
    const [isOpenAcceptedRiderModal, setIsOpenAcceptedRiderModal] = useState<boolean>(false);
    const [isOpenEligibleRiderModal, setIsOpenEligibleRiderModal] = useState<boolean>(false);
    const [selectedOrder, setSelectedOrder] = useState<IOngoingOrders>({} as IOngoingOrders);

    const memoizedOrderItems = useMemo(() => {
        let memoizedOrders = orders.map(order => ({ ...order, status: order.status }));
        return memoizedOrders;
    }, [orders]);

    useEffect(() => {
        orders.length > 0 && setShowNoResultFound(false);
    }, [orders]);

    const getOrderList = useCallback(async () => {
        console.log("=== Orders List API ===");
        const {start, end} = dateHelper.getFromStartUntilXHoursAgo(new Date(), 2);
        const fromToRange = dateHelper.getStartEndByDatesAndTimes(start, end);
        try {
            const result = await ordersService.getOngoingOrders({
                count: DEFAULT_COUNT,
                offset: DEFAULT_OFFSET,
                fromDate: fromToRange.end,
                toDate: fromToRange.start
            }, getAbortController.signal);

            dispatch({
                type: OrdersDataStoreActionKind.SET_ORDERS,
                data: {
                    orders: result.data.data.items,
                    pendingCount: result.data.data.pendingCount
                }
            });
            result.data.data.items.length > 0 ? setShowNoResultFound(false) : setShowNoResultFound(true);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }, [getAbortController]);

    useEffect(() => {
        if (allowToCallAPI && tabIsActive) {
            getOrderList();
        }
        // when user change tab
        if (!tabIsActive) {
            console.log("----->", tabIsActive);
            setShowNoResultFound(false);
            setLoading(true);
        }
    }, [getOrderList, allowToCallAPI, tabIsActive]);

    useEffect(() => {
        return () => {
            getAbortController.abort();
        }
    }, []);

    return loading
        ? <Center minHeight="200px">
            <SPSpinner size="lg"/>
        </Center>
        : <>
        {/* <TableContainer mt={1}> */}
            {!showNoResultFound
                ? <Table
                    variant="simple"
                    colorScheme="blackAlpha"
                    size="md"
                    boxShadow="md"
                    backgroundColor="white"
                    borderRadius="md"
                >
                    <Thead bg="#E6E6E6" color="#808080" position="sticky" top="60px" zIndex={2}>
                        <Tr>
                            <Th>{t("fields.orderId")}</Th>
                            <Th>{t("fields.OTP")}</Th>
                            <Th>{t("fields.orderValue")} (KD)</Th>
                            <Th>{t("fields.groupId")}</Th>
                            <Th>{t("fields.zoneName")}</Th>
                            <Th>{t("fields.pending")}</Th>
                            <Th>{t("fields.accepted")}</Th>
                            <Th>{t("fields.pickedUp")}</Th>
                            <Th>{t("fields.onTheWay")}</Th>
                            <Th>{t("fields.delivered")}</Th>
                            <Th>{t("fields.action")}</Th>
                        </Tr>
                    </Thead>
                    <Tbody border="1px solid #F9F9F9">
                        {
                            memoizedOrderItems.map(orderItem => {
                                return <OngoingOrderItem
                                    key={orderItem.id}
                                    orderItem={orderItem}
                                    setSelectedOrder={setSelectedOrder}
                                    setIsOpenAcceptedRiderModal={setIsOpenAcceptedRiderModal}
                                    setIsOpenEligibleRiderModal={setIsOpenEligibleRiderModal} />;
                            })
                        }
                    </Tbody>
                </Table> : <NotFoundResult />}
        {/* </TableContainer> */}
        {/* Accepted Rider Modal */}
        <AcceptedRiders
            orderItem={selectedOrder}
            isOpen={isOpenAcceptedRiderModal}
            handleClose={() => setIsOpenAcceptedRiderModal(false)} />
        {/* Eligible Riders Modal */}
        <EligibleRiders
            orderItem={selectedOrder}
            isOpen={isOpenEligibleRiderModal}
            handleClose={() => setIsOpenEligibleRiderModal(false)} />
    </>;
}

export default OngoingOrderList;