import React, { useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Switch,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import { useNavigate, useParams } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ContentView, {
  ContentViewBody,
  ContentViewHeader,
} from "src/components/app/ContentView";
import { BrowserBack } from "src/components/app/BrowserBack";
import {
  INewTargetGroup,
  ITargetGroupListItem,
} from "../../../../api/types/target-groups";
import { newTargetGroupValidationSchema } from "../../../../validation-schemas/target-group";
import { useAsync } from "../../../../hooks/useAsync";
import { panelTargetGroupsService } from "../../../../api/services/target-groups";
import { useAbortController } from "../../../../hooks/useAbortController";
import HorizontalLoading from "../../../../components/app/HorizontalLoading";
import { toast } from "../../../../utils/toast";
import { useChangeTargetGroupActivity } from "../useChangeActivity";
import { modalActions } from "../../../../global-context/modals";
import { MODAL_TYPES } from "../../../../types/modals";

const CreateTargetGroup: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const isEditable = Boolean(params.id);
  const { getAbortSignal } = useAbortController();
  useTitle(
    t(isEditable ? "pageTitles.editTargetGroup" : "pageTitles.newTargetGroup")
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm<INewTargetGroup>({
    resolver: yupResolver(newTargetGroupValidationSchema(t)),
    mode: "all",
    defaultValues: {},
  });

  const changeTargetGroupActivity = useChangeTargetGroupActivity(params.id!);

  const getDetailAsync = useAsync<ITargetGroupListItem, unknown>(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await panelTargetGroupsService.get(
          params.id!,
          getAbortSignal("getDetail").signal
        );
        reset(result.data.data);
        changeTargetGroupActivity.setIsActive(
          result.data?.data?.active || false
        );
        resolve(result.data.data);
      } catch (e) {
        reject(e);
      }
    });
  });

  const submitAsync = useAsync<unknown, INewTargetGroup>((payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        let result;
        if (isEditable) {
          result = await panelTargetGroupsService.update(
            params.id!,
            payload,
            getAbortSignal("submit").signal
          );
          toast.success(t("messages.targetGroupUpdatedSuccessfully"));
        } else {
          result = await panelTargetGroupsService.create(
            payload,
            getAbortSignal("submit").signal
          );
          toast.success(t("messages.targetGroupCreatedSuccessfully"));
        }
        navigate(-1);
        resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  });

  const onSubmit = (values: INewTargetGroup) => {
    modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
      title: `These changes will affect the previous reports as well , are you sure ?`,
      iconType: "attention",
      shouldAcceptOrReject: true,
      onAccept: () => {
        submitAsync.run(values);
        modalActions.clearModals();
      },
      onReject: () => {
        modalActions.clearModals();
      },
    });
  };

  useEffect(() => {
    if (isEditable) {
      getDetailAsync.run({});
    }
  }, [isEditable]);

  return (
    <ContentView>
      <ContentViewHeader>
        <Box display="flex" alignItems="center">
          <Text as="h3" fontSize="3xl">
            {t(isEditable ? "titles.editTargetGroup" : "titles.newTargetGroup")}
          </Text>
          <BrowserBack />
        </Box>
        <Box
          mb="4"
          mt="4"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        ></Box>
      </ContentViewHeader>

      <ContentViewBody>
        {submitAsync.isLoading ||
          (changeTargetGroupActivity.isLoading && <HorizontalLoading />)}
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          {isEditable && (
            <FormControl mb={"4"}>
              <FormLabel
                style={{
                  fontWeight: 500,
                  display: "inline",
                  color: changeTargetGroupActivity.active
                    ? "var(--chakra-colors-yellow-500)"
                    : "",
                }}
              >
                {changeTargetGroupActivity.active ? "Enable" : "Disable"} Target
                Group
              </FormLabel>
              <Switch
                ml={"2"}
                colorScheme={
                  changeTargetGroupActivity.active ? "yellow" : "gray"
                }
                isChecked={changeTargetGroupActivity.active}
                onChange={(e) => {
                  changeTargetGroupActivity.run(e.target.checked);
                }}
              />
            </FormControl>
          )}

          <Box mb="4">
            <FormControl isInvalid={!!errors.name} isRequired>
              <FormLabel htmlFor="nameEn">{t("fields.nameEn")}</FormLabel>
              <Input id="name" type="text" size="lg" {...register("name")} />
              <FormErrorMessage>
                {!!errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>
          </Box>

          <Box mb="4">
            <FormControl isInvalid={!!errors.veryGood} isRequired>
              <FormLabel htmlFor="veryGood">{t("fields.veryGood")}</FormLabel>
              <Input
                id="veryGood"
                type="number"
                size="lg"
                {...register("veryGood")}
              />
              <FormErrorMessage>
                {!!errors.veryGood && errors.veryGood.message}
              </FormErrorMessage>
            </FormControl>
          </Box>
          <Box mb="4">
            <FormControl isInvalid={!!errors.good} isRequired>
              <FormLabel htmlFor="good">{t("fields.good")}</FormLabel>
              <Input id="good" type="number" size="lg" {...register("good")} />
              <FormErrorMessage>
                {!!errors.good && errors.good.message}
              </FormErrorMessage>
            </FormControl>
          </Box>
          <Box mb="4">
            <FormControl isInvalid={!!errors.dailyTarget} isRequired>
              <FormLabel htmlFor="dailyTarget">
                {t("fields.dailyTarget")}
              </FormLabel>
              <Input
                id="dailyTarget"
                type="number"
                size="lg"
                {...register("dailyTarget")}
              />
              <FormErrorMessage>
                {!!errors.dailyTarget && errors.dailyTarget.message}
              </FormErrorMessage>
            </FormControl>
          </Box>

          <Box mb="4">
            <FormControl isInvalid={!!errors.bad} isRequired>
              <FormLabel htmlFor="bad">{t("fields.bad")}</FormLabel>
              <Input id="bad" type="number" size="lg" {...register("bad")} />
              <FormErrorMessage>
                {!!errors.bad && errors.bad.message}
              </FormErrorMessage>
            </FormControl>
          </Box>

          <Box mb="4">
            <FormControl isInvalid={!!errors.veryBad} isRequired>
              <FormLabel htmlFor="veryBad">{t("fields.veryBad")}</FormLabel>
              <Input
                id="veryBad"
                type="number"
                size="lg"
                {...register("veryBad")}
              />
              <FormErrorMessage>
                {!!errors.veryBad && errors.veryBad.message}
              </FormErrorMessage>
            </FormControl>
          </Box>

          <Box textAlign="right">
            <Button
              onClick={() =>
                isEditable
                  ? navigate(-1)
                  : navigate(
                      ROUTE_CONSTANTS.DASHBOARD.TARGET_GROUPS.ROOT.ABSOLUTE
                    )
              }
              isLoading={submitAsync.isLoading}
              size="lg"
              colorScheme="orange"
              mr="4"
            >
              {t("actions.cancel")}
            </Button>
            <Button
              colorScheme="primary"
              size="lg"
              type="submit"
              isLoading={submitAsync.isLoading}
            >
              {t("actions.save")}
            </Button>
          </Box>
        </form>
      </ContentViewBody>
    </ContentView>
  );
};

export default CreateTargetGroup;
