import { TFunction } from "react-i18next";
import { OrderPaymentType } from "src/api/types/orders";
import { VehicleType } from "src/api/types/vehicle";
import { mixed, number, object, string } from "yup";

export const adminCreateUpdateOrderValidation = (t: TFunction<"translation", undefined>) => object().shape({
    code: string().trim().required(t("validationMessages.fieldIsRequired", { field: "Order ID" })),
    cost: number()
        .min(0, t("validationMessages.minValueValidation", { field: "Order Value", validValue: 0 }))
        .required(t("validationMessages.fieldIsRequired", { field: "Order Value" })),
    paymentType: mixed().oneOf([OrderPaymentType.Cash, OrderPaymentType.Paid]).required(),
    vehicleType: mixed().oneOf([VehicleType.Bike, VehicleType.Car]).required(),
    registeredDate: string().required(t("validationMessages.fieldIsRequired", { field: "Order Date" })),
    riderId: string().trim().required(t("validationMessages.fieldIsRequired", { field: "Rider" })),
    zoneId: string().trim().required(t("validationMessages.fieldIsRequired", { field: "Zone" })),
    groupId: string().trim().required(t("validationMessages.fieldIsRequired", { field: "Group" })),
    imageUrl: string().required(t("validationMessages.fieldIsRequired", { field: "Receipt image" }))
});