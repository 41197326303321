import { Box, Button, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { panelThirdPartiesService } from "src/api/services/third-party";
import { toast } from "src/utils/toast";

interface IChangeStateConfirmationProps {
    item: {
        id: string;
        name: string;
        isActive:boolean;
    }
    openSwitchConfirmationModal: boolean;
    setOpenSwitchConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleChangeState: (newState: boolean) => void;
}

const ChangeStateConfirmation: React.FC<IChangeStateConfirmationProps> = (props) => {
    const { item, openSwitchConfirmationModal, setOpenSwitchConfirmationModal, handleChangeState} = props;
    const {id, name, isActive } = item;

    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [abortController] = useState<AbortController>(new AbortController());

    const handleUserChangeState = async () => {
        setLoading(true);
        try {
            if(isActive) {
                await panelThirdPartiesService.toDisableItem(id, abortController.signal);
                toast.success(t("messages.entityWasActionedSuccessfully", {entity: "Third Party", state: "disabled"}));
            } else {
                await panelThirdPartiesService.toEnableItem(id, abortController.signal);
                toast.success(t("messages.entityWasActionedSuccessfully", {entity: "Third Party", state: "enabled"}));
            }
            setOpenSwitchConfirmationModal(false);
            handleChangeState(!isActive);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        return () => abortController.abort();
    }, []);

    return <Modal closeOnOverlayClick={false} isOpen={openSwitchConfirmationModal} onClose={() => setOpenSwitchConfirmationModal(false)}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader textAlign="center" mb="-3" mt="3" fontSize="1em">
                <Text as="h3" fontSize="1.1rem" fontWeight="500">Are you sure that you want to {isActive ? "disable" : "enable"} third party?</Text>
                <Box fontWeight="medium" fontSize="1rem" display="flex" justifyContent="center" gap={2} mt={1}>
                    <Text>Company Name:</Text>
                    <Text color="#aaa">{name}</Text>
                </Box>
            </ModalHeader>
            <ModalBody>
                <Box display="flex" mt="4" mb="4" gap="4">
                    <Button flex="1" variant="outline" ml="2" onClick={() => setOpenSwitchConfirmationModal(false)} isDisabled={loading}>No</Button>
                    <Button flex="1" variant="yellow_100" onClick={handleUserChangeState} isLoading={loading}>Yes</Button>
                </Box>
            </ModalBody>
        </ModalContent>
    </Modal>;
}

export default ChangeStateConfirmation;