import { FormControl, FormErrorMessage, FormLabel, Select } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { panelGroupsService } from "src/api/services/groups";
import { panelZonesService } from "src/api/services/zones";
import { ZoneSorts } from "src/api/types/zones";
import SPSpinner from "src/components/app/SPSpinner";
import { DEFAULT_COUNT, DEFAULT_OFFSET } from "src/constants/api-default-values";
import { USER_ROLE, USER_ROLE_TYPES } from "src/types/user-management";

interface IRoleInputDependencyProps {
    userRoleType: USER_ROLE_TYPES;
    methods: UseFormReturn<any, any>;
}

const RoleInputDependency: React.FC<IRoleInputDependencyProps> = ({ userRoleType, methods }) => {
    const { register, setValue, formState: {errors} } = methods;
    const [abortController] = useState<AbortController>(new AbortController());
    const [items, setItems] = useState<{
        id: string,
        name: string;
    }[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const fetchData = async () => {
        setLoading(true);
        try {
            const result = userRoleType === USER_ROLE.GroupAgent 
                            ? await panelGroupsService.getAll(DEFAULT_OFFSET, DEFAULT_COUNT, abortController.signal)
                            : await panelZonesService.getAll(DEFAULT_OFFSET, DEFAULT_COUNT, ZoneSorts.ByNameEnAsc, abortController.signal);
            let data = [];
            data = result.data.data.items.map(item => Object.assign({id: item.id, name: item.nameEn}, item));
            setItems(data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [userRoleType]);

    return loading
        ? <SPSpinner/>
        : <FormControl isInvalid={!!errors.roleDependency}>
            <FormLabel>{(userRoleType === USER_ROLE.GroupAgent) ? "Select Group" : "Select Zone"}</FormLabel>
            <Select size="lg" {...register("roleDependency")}>
                <option value="">{(userRoleType === USER_ROLE.GroupAgent) ? "- Please select a group -" : "- Please select a zone -"}</option>
                {
                    items.map(item => (
                        <option key={item.id} value={item.id}>{item.name}</option>
                    ))
                }
            </Select>
            <FormErrorMessage>{!!errors.roleDependency && errors.roleDependency?.message }</FormErrorMessage>
        </FormControl>;
}

export default RoleInputDependency;